@import 'sources/normalize.scss';
@import 'sources/variables.scss';
@import 'sources/mixins.scss';
@import 'sources/fonts.scss';
@import 'sources/keyframes.scss';
@import 'sources/loaders.scss';

::-ms-clear {
  display: none;
}

* {
  word-break: break-word;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  &:focus {
    -moz-outline-style: none;
    outline: none !important;
  }
}
section {
  position: relative;
}
iframe {
  display: block;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
td {
  vertical-align: middle;
}
button {
  border: none;
}
img {
  display: block;
  -ms-interpolation-mode: bicubic;
}
a {
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
html {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
}
body {
  background: #f6f7fb;
  font-stretch: normal;
  min-height: 100%;
  font-family: $main-font-regular;
  position: relative;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
}
.clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
input::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  opacity: 0;
}
[readonly] {
  user-select: none;
}
[type=file] {
  max-width: 100%;
}
::after,
::before{
  box-sizing: border-box;
}

/* scrollbar width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 5px grey;
  //border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.1);
  border-radius: 15px;
}

.strong {
  font-weight: 700;
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.react-tiny-popover-container {
  z-index: 10;
}
