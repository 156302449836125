@import '../../assets/sources/variables.scss';
@import '../../assets/sources/mixins.scss';

@include keyframes(upload) {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  40%,
  60% {
    opacity: 1;
  }
  90% {
    transform: translateY(rem(-20));
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@include keyframes(opacity) {
  0% { 
    opacity: 0;
  }
  100% { 
    opacity: 1;
  }
}

@include keyframes(slide-left) {
  0% { 
    transform: translateX(100%);
  }
  100% { 
    transform: translateX(0);
  }
}

@include keyframes(fadeInUp) {
  0% { 
    opacity: 0;
    transform: translate3d(0, rem(100), 0);
  }
  100% { 
    opacity: 1;
    transform: none;
  }
}

@include keyframes(progress-bar) {
  0% { 
    width: 0;
  }
}

.opacity-enter {
  opacity: 0;
  &.opacity-enter-active {
    opacity: 1;
    transition: opacity 200ms linear;
  }
}
.opacity-leave {
  opacity: 1;
  &.opacity-leave-active {
    opacity: 0;
    transition: opacity 200ms linear;
  }
}
.opacity-appear {
  opacity: 0;
  &.opacity-appear-active {
    opacity: 1;
    transition: opacity 200ms linear;
  }
}


.slide-up-appear {
  opacity: 0;
  transform: translateY(rem(100));
  transition: opacity 500ms ease, transform 500ms ease;
  will-change: opacity, transform;
  .order-list__subitem {
    opacity: 0;
    transform: translateY(rem(100));
    transition: opacity 500ms ease, transform 500ms ease;
    will-change: opacity, transform;
  }
  &.slide-up-appear-active {
    opacity: 1;
    transform: translateY(0);
    .order-list__subitem {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.slide-up-enter {
  opacity: 0;
  transform: translateY(rem(100));
  transition: opacity 500ms ease, transform 500ms ease;
  will-change: opacity, transform;
  .order-list__subitem {
    opacity: 0;
    transform: translateY(rem(100));
    transition: opacity 500ms ease, transform 500ms ease;
    will-change: opacity, transform;
  }
  &.slide-up-enter-active {
    opacity: 1;
    transform: translateY(0);
    .order-list__subitem {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.slide-up-dropdown-appear {
  opacity: 0;
  transform: translateY(rem(12));
  transition: opacity 300ms ease, transform 300ms ease;
  will-change: opacity, transform;
  z-index: 2;
  &.slide-up-dropdown-appear-active {
    opacity: 1;
    transform: translateY(0);
  }
}
.slide-up-dropdown-enter {
  opacity: 0;
  transform: translateY(rem(12));
  transition: opacity 300ms ease, transform 300ms ease;
  will-change: opacity, transform;
  &.slide-up-dropdown-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
}
.slide-up-dropdown-leave {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease, transform 300ms ease;
  will-change: opacity, transform;
  &.slide-up-dropdown-leave-active {
    opacity: 0;
    transform: translateY(rem(12));
  }
}

.slide-left-appear {
  animation-name: slide-left;
  animation-timing-function: linear;
  animation-duration: 200ms;
  &.slide-left-appear-active {
    //animation-duration: 200ms;
  }
}

.progress-bar-appear {
  .status__progress-bar {
    animation-name: progress-bar;
    animation-timing-function: ease;
    animation-duration: 500ms;
    will-change: width;
  }
  &.progress-bar-appear-active {
    .status__progress-bar {
      
    }
  }
}
.progress-bar-enter {
  .status__progress-bar {
    animation-name: progress-bar;
    animation-timing-function: ease;
    animation-duration: 500ms;
    will-change: width;
  }
  &.progress-bar-enter-active {
    .status__progress-bar {
      
    }
  }
}

.orderFormAnimation-appear {
  animation: fadeInUp 500ms ease;
  &.orderFormAnimation-appear-active {
    
  }
}

.slide-down-enter {
  opacity: 0;
  height: 0;
  padding: 0;
  margin: 0;
  transition: opacity 300ms ease, height 300ms ease, padding 300ms ease, margin 300ms ease;
  will-change: opacity, height;
  &.slide-down-enter-active {
    opacity: 1;
    height: rem(116);
  }
}
.slide-down-leave {
  opacity: 1;
  height: rem(116);
  transition: opacity 300ms ease, height 300ms ease, padding 300ms ease, margin 300ms ease;
  will-change: opacity, height;
  &.slide-down-leave-active {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}