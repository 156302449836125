@import './mixins.scss';
@import './variables.scss';

@include keyframes(blink) {
  50% {
    opacity: 0.9;
    transform: scale(1.67);
  }
}

@include keyframes(loading) {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@include keyframes(shake) {
  0% { 
    transform: translateX(0); 
  }
  25% { 
    transform: translateX(rem(8)); 
  }
  75% { 
    transform: translateX(rem(-8)); 
  }
  100% { 
    transform: translateX(0); 
  }
}

@include keyframes(shake2) {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


