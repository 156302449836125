@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
  &::-webkit-text-fill-color { @content; }
}

$main_font_size: 16;
@function em($value) {
  @return calc($value / $main_font_size) * 1em;
}
@function rem($value) {
  @return calc($value / $main_font_size) * 1rem;
}
@function emr($value, $parent) {
  @return calc($value / $parent) * 1em;
}
@function lh($value) {
  @return calc($value / $main_font_size);
}
@function lhr($value, $parent) {
  @return calc($value / $parent);
}

@mixin caret($point, $width, $height, $color) {
  $opp: opposite-position($point);
  content: '';
  position: absolute;
  border: $width solid transparent;
  border-#{$opp}: $height solid $color;
  border-#{$point}: 0;
  width: 0;
  height: 0;
}

@mixin circle($d) {
  display: inline-block;
  width: $d;
  height: $d ;
  border-radius: 50%;
}

@mixin ib {
  display: -moz-inline-stack;
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

@mixin center {
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
}

@mixin left {
  text-align: left;
  -moz-text-align-last: left;
  text-align-last: left;
}

@mixin right {
  text-align: right;
  -moz-text-align-last: right;
  text-align-last: right;
}

@mixin justify {
  text-align: justify;
  text-justify: newspaper;
  -moz-text-align-last: justify;
  text-align-last: justify;
}

@mixin triangle($width, $height, $color, $direction) {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  display: block;
  z-index: 2;
  // for a better anti-aliasing in webkit browser
  //+transform(rotate(360deg))

  @if $direction == right {
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-left: $width solid $color;
    border-right: none;
  }

  @if $direction == left {
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-right: $width solid $color;
    border-left: none;
  }

  @if $direction == up {
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-bottom: $height solid $color;
    border-top: none;
  }

  @if $direction == down {
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-top: $height solid $color;
    border-bottom: none;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} { @content }
  @-moz-keyframes #{$name} { @content }
  @-ms-keyframes #{$name} { @content }
  @keyframes #{$name} { @content }
}

@mixin fb-respond-to($media) {
  @if $media == md {
    @media all and (min-width: $md) and (max-width: $lg) {
      @content
    }
  }
  @else if $media == lg {
    @media all and (min-width: $lg) {
      @content
    }
  }
  @else if $media == mdlg {
    @media all and (min-width: $md) {
      @content
    }
  }
}

// 1280+
@mixin desktopMax {
  @media only screen and (min-width: $desktopMax) {
    @content;
  }
}

// 1024 - 1279
@mixin desktopMin {
  @media only screen and (min-width: $desktopMin) and (max-width: $desktopMax - 1) {
    @content;
  }
}

// 768 - 1023
@mixin tablet {
  @media only screen and (min-width: $tablet) and (max-width: $desktopMin - 1) {
    @content;
  }
}

// 320 - 1023
@mixin mobileWithoutTablet {
  @media only screen and (min-width: $mobile) and (max-width: $desktop - 1) {
    @content;
  }
}

// 320 - 767
@mixin mobile {
  @media only screen and (min-width: $mobile) and (max-width: $tablet - 1) {
    @content;
  }
}

@mixin clearfix {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

@mixin retina2 {
  @media 
  only screen and (-webkit-min-device-pixel-ratio: 2), 
  only screen and (min-resolution: 192dpi), 
  only screen and (min--moz-device-pixel-ratio: 2), 
  only screen and (-o-min-device-pixel-ratio: 2/1), 
  only screen and (min-device-pixel-ratio: 2), 
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin retina3 {
  @media 
  only screen and (-webkit-min-device-pixel-ratio: 3), 
  only screen and (min-resolution: 288dpi), 
  only screen and (min--moz-device-pixel-ratio: 3), 
  only screen and (-o-min-device-pixel-ratio: 3/1), 
  only screen and (min-device-pixel-ratio: 3), 
  only screen and (min-resolution: 3dppx) {
    @content;
  }
}
