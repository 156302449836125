@mixin mobile {
  @media only screen and (max-width: 1280 - 1px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

.cookie-popup {
  position: fixed;
  background: rgba(0, 0, 0, 0.90);
  box-shadow: 4px 4px 8px 0 rgba(37, 38, 93, 0.08), -4px -4px 8px 0px rgba(37, 38, 93, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  z-index: 999;
  @include mobile {
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
  }
  @include desktop {
    left: 20px;
    bottom: 20px;
    width: 512px;
    height: 188px;
    border-radius: 8px;
    padding: 24px;
  }
  .svg-icon {
    display: block;
  }

  .message {
    //font-family: $circular;
    color: #FFF;
    font-size: 12px;
    line-height: 20px;
  }
  .link {
    font-weight: 700;
    text-decoration-line: underline;
    white-space: nowrap;
    color: inherit;
  }
  .buttons {
    display: flex;
    align-self: stretch;
    gap: 12px;
    @include mobile {
      flex-direction: column-reverse;
      margin-top: 24px;
    }
    @include desktop {
      margin-top: auto;
    }
  }
  button {
    background-color: transparent;
    padding: 0;
    color: inherit;
  }
  .button {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    padding: 0 10px;
    &.type1 {
      color: #3D77FF;
      @include mobile {
        flex-basis: 0;
      }
    }
    &.type2 {
      border-radius: 100px;
      border: 2px solid #3D77FF;
      background: transparent;
      color: #3D77FF;
      flex-grow: 1;
      @include desktop {
        flex-basis: 0;
      }
    }
    &.type3 {
      border-radius: 100px;
      background: #3D77FF;
      color: #FFF;
      flex-grow: 1;
      @include desktop {
        flex-basis: 0;
      }
    }
  }
  .titleBlock {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
  .checkboxBlock {
    @include mobile {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 16px;
    }
    @include desktop {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
  .checkboxItem {
    display: flex;
    align-items: center;
  }
  .hiddenCheckbox {
    display: none;
  }
  .checkboxLabel {
    display: flex;
    gap: 8px;
    cursor: pointer;
  }
  .checkboxTitle {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 1px;
  }
  .checkboxTitle_disabled {
    opacity: 0.56;
  }
}

/*
@include mobile {
}
@include desktop {
}
*/
