@import '../../../assets/sources/variables';
@import '../../../assets/sources/mixins';

@font-face {
  font-family: 'CircularStd-Bold';
  src: local('CircularStd-Bold'),
    url('fonts/CircularStd-Bold.woff2') format('woff2'),
    url('fonts/CircularStd-Bold.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd-Medium';
  src: local('CircularStd-Medium'),
    url('fonts/CircularStd-Medium.woff2') format('woff2'),
    url('fonts/CircularStd-Medium.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd-Book';
  src: local('CircularStd-Book'),
    url('fonts/CircularStd-Book.woff2') format('woff2'),
    url('fonts/CircularStd-Book.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

.ui-chat {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
}

.ui-chat__overlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ui-chat__holder {
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  //@include desktop {
  //  width: 464px;
  //  height: 560px;
  //  border-radius: 16px;
  //  transform: translate3d(0, -50%, 0);
  //  top: 50%;
  //  margin: 0 auto;
  //}
}

.ui-chat-btn {
  //@include tabletDesktop {
  //  bottom: 50px;
  //}

  @include mobileWithoutTablet {
    bottom: 105px;
  }
}
